


































import { Component, Vue } from 'vue-property-decorator'
import LandingTitle from './LandingTitle.vue'

@Component({ name: 'LandingStat', components: { LandingTitle } })
export default class LandingStat extends Vue {
  list = [
    {
      id: 1,
      title: 'Площадок, кабинетов и залов уже на сайте',
      count: 908,
      color: '#ff002c',
      shadow: '0 20px 40px 0 rgba(255, 0, 44, 0.35)',
    },
    {
      id: 2,
      title: 'Посетителей <br/> &nbsp;',
      count: 13089,
      color: '#52bfac',
      shadow: '0 20px 40px 0 rgba(82, 191, 172, 0.35)',
    },
    {
      id: 3,
      title: 'Количество <br/> просмотров объявлений',
      count: 25048,
      color: '#2b61d2',
      shadow: '0 20px 40px 0 rgba(43, 97, 210, 0.35)',
    },
    {
      id: 4,
      title: 'Среднее количество <br/> звонков и заявок',
      count: 2758,
      color: '#ffbd00',
      shadow: '0 20px 40px 0 rgba(255, 189, 0, 0.35)',
    },
  ]
}
