





























































import { Component, Vue } from 'vue-property-decorator'

import BaseButton from '@/components/base/BaseButton.vue'
import LandingTitle from './LandingTitle.vue'

@Component({ name: 'LandingInfo', components: { LandingTitle, BaseButton } })
export default class LandingInfo extends Vue {
  list = [
    {
      id: 1,
      name: null, //'Просмотры ваших помещений',
      text: 'Регистрируйтесь и заполняйте максимальное количество информации об объекте, подписывайтесь на телеграм бот.',
      icon: null, //'eye.svg',
    },
    {
      id: 2,
      name: null, //'Статистика звонков',
      text: 'Пополните Счет в вашем личном кабинете. Оплатите тариф.',
      icon: null, //'phone.svg',
    },
    {
      id: 3,
      name: null, //'Рейтинг и отзывы арендаторов',
      text: 'Для быстрого достижения эффекта - подключите премиум',
      icon: null, //'rating.svg',
    },
    {
      id: 4,
      name: null, //'Рейтинг и отзывы арендаторов',
      text: 'Получайте звонки и заявки от клиентов',
      icon: null, //'rating.svg',
    },
  ]
}
